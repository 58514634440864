<template>
<v-container>
  <div class="header">
      <span>
        <h3>Tipos de stackholders</h3>

        <p>Listagem de tipos de stackholders</p>
      </span>

      <div class="grow"/>

      <button type="button" class="btn" @click="() => this.modalCriarTipos = true">
        <v-icon color="#f9f9f2" size='28'>mdi-plus-circle-outline</v-icon>
      </button>
    </div>

    <div class="divContrato">
      <v-data-table 
        :items="tipos"
        :headers="headers"
        :items-per-page="15"
      >

        <template v-slot:[`item.cliente`]="{ item }">
          <v-simple-checkbox v-model="item.cliente">
          </v-simple-checkbox>
        </template>

        <template v-slot:[`item.ativo`]="{ item }">
          <v-simple-checkbox v-model="item.ativo">
          </v-simple-checkbox>
        </template>

      </v-data-table>
      <div style="display:flex; justify-content: end;">
        <button class="button" @click="salvarStackHolder">{{ textoBotao }}</button>
      </div>
    </div>

    <v-dialog v-model="modalCriarTipos" width="600px" max-width="50vw" persistent>
      <div style="background-color:white;width:100%;display:flex;flex-direction:column; padding: 10px; justify-content: center; align-items: center;text-align: center;">
          <h2 style="margin: 15px 0 15px 0">Cadastrar tipos de stackholders</h2>
          <input class="inputName" type="text" placeholder="Nome" v-model="stackholder.nome"/>
          <div style="display: flex; flex-direction: row; width: 100%; padding: 5px;">
            <input type="checkbox" v-model="stackholder.cliente" style="margin: 0 5px 0 0" id="tipoCliente" name="tipoCliente" checked>
            <label for="tipoCliente">Tipo cliente</label>
          </div>
          <div style="display: flex; width: 100%; justify-content: end;">
            <button class="button" @click="() => this.modalCriarTipos = false">Cancelar</button>
            <button class="button" style="margin-left: 10px;" @click="cadastrarStackHoldersTipos">{{ textoBotaoCadastrar }}</button>
          </div>
      </div>
    </v-dialog>

    <v-dialog v-model="alerta" width="300px" max-width="50vw" persistent>
      <div style="background-color:white;width:100%;display:flex;flex-direction:column; padding: 10px; justify-content: center; align-items: center;text-align: center;">
          <h2>Atenção</h2>
          <p>{{ mensagem }}</p>
          <button class="button" @click="() => this.alerta = false">OK</button>
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
  import stakeholderService from '../../services/stakeholder.service';
  export default {
    data() {
      return {
        headers: [
        { text: 'Nome', value: 'nome' },
        { text: 'Cliente', value: 'cliente'},
        { text: 'Ativo', value: 'ativo'}
      ],
        tipos: [],
        loading: true,
        textoBotao: 'Salvar',
        alerta: false,
        mensagem: null,
        modalCriarTipos: false,
        stackholder: {
          nome: null,
          cliente: false,
        },
        textoBotaoCadastrar: 'Cadastrar'
      }
    },
    methods: {
      async buscarStakeHoldersTipo() {
        try {
          const result = await stakeholderService.buscarTipo();
          this.tipos = result.data;
          console.log(result.data);
        } catch (error) {
          console.log(error);
        }
      },

      async salvarStackHolder() {
        this.textoBotao = 'Arguarde...'
        try {
          const result = await stakeholderService.atualizarTiposStackholders(this.tipos);
          this.mensagem = 'Tipos de stackholders salvos com sucesso :)'
          this.alerta = true;
          this.textoBotao = 'Salvar'
          console.log(result);
        } catch (error) {
          this.textoBotao = 'Salvar'
          console.log(error.response);
        }
      },

      async cadastrarStackHoldersTipos() {
        this.textoBotaoCadastrar = 'Arguarde...'
        try {
          if(!this.stackholder.nome) {
            this.mensagem = 'Campos inválidos!';
            this.alerta = true;
            return
          }
          console.log(this.stackholder);
          await stakeholderService.cadastrarTiposStackholders(this.stackholder);
          this.stackholder.nome = null;
          this.textoBotaoCadastrar = 'Cadastrar'
          this.buscarStakeHoldersTipo();
          this.modalCriarTipos = false;
        } catch (error) {
          this.textoBotaoCadastrar = 'Cadastrar'
          console.log(error);
        }
      }
    },
    async mounted() {
      await this.buscarStakeHoldersTipo()
      this.loading = false;
    }
  }
</script>

<style lang="scss" scoped>
.divContrato {
  margin: 0 auto 5em auto;
  width: 900px;
  max-width: 80vw;
  box-shadow:  4px 4px #d9d9d9;
  padding: 3em 1em 1em 1em;
  border-radius: 30px;
  background-color: #f9f9f2;
}

.inputName {
  background-color: white;
  border: 1px solid rgb(122, 122, 122);
  margin: 0 0 10px;
  border-radius: 5px;
  padding: 8px;
  width: 100%;
}

.button {
  background-color: #3A78F2;
  color: white;
  padding: 5px;
  width: 100px;
  border-radius: 5px;
  margin: 10px 0 0 0;
}

.header{
  display: flex;
  padding: 1em;
  margin: 0 auto;
  width: 80vw;
  max-width: 900px;

  >h3{
    letter-spacing: 0.02em;
  }
  margin: 1em auto;
  background: rgba(215,211,222,0.5);
  height: fit-content;
  border-radius: 10px;
  >span{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.btn{
      padding: .75em 1em;
      line-height: 1.5;
      color: white;
      font-weight: bold;
      letter-spacing: 0.02em;
      background: #1377f2;
      border-radius: 10px;
      text-transform: capitalize;
  }
</style>